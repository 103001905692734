<template>

	<div class="tab-content mt-6">
		<table class="table table-striped brand">
			<thead>
				<tr>
					<th class="col-1"></th>
					<th class="col-3">Student Name</th>
					<th class="col-2">Status</th>
					<th class="col-2">Date Started</th>
					<th class="col-3"></th>
				</tr>
			</thead>
			<tbody>

		<CourseAnalyticsAlignedUser
			v-for="user in allUsers"
			:key="user.id"
			:user="user"
		/>

			</tbody>
		</table>
	</div>

</template>

<script>
import AdminDefaultSubheader from "@/components/AdminDefaultSubheader.vue";
import CourseAnalyticsAlignedUser from "./components/CourseAnalyticsAlignedUser.vue";

export default {
	name: "CourseAnalyticsAll",
	components: {
		CourseAnalyticsAlignedUser,
		AdminDefaultSubheader
	},
	props: ["allUsers"]
};
</script>

<style></style>
